(function () {
    var tempId = "none";

    var isBrowserSupported = function () {
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;

        var isIndexPage = window.location.pathname == '/app/index.html';

        if (isIE11 || isEdge) {
            return isIndexPage;
        } else {
            return !isIE;
        }
    };

    if (!isBrowserSupported()) {
        window.location.href = '/app/sorry.html';
    }

    if (window.eHanlin === undefined || window.eHanlin === null) {
        window.eHanlin = {};
    }

    if (window.eHanlin.platform_env === undefined || window.eHanlin.platform_env === null) {
        window.eHanlin.platform_env = {};
    }

    //本機伺服器開發
    window.eHanlin.platform_env.site = 'local-server';
    window.eHanlin.platform_env.cdn = 'https://ehanlin-web-public-resource.s3-ap-northeast-1.amazonaws.com';

    function setupGoogleAnalytics4(){
        let tmpStore = [];
        window.ga = (...args) =>{
            tmpStore.push(args);
        }
        let script = document.createElement('script')
        script.src = "https://www.googletagmanager.com/gtag/js?id=G-KQP8HNH5GP"
        script.onload = () => {
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-KQP8HNH5GP', {
                'page_title' : document.title
            });

            gtag('config', 'AW-16639420447');

            window.ga = (...args) =>{
                if(args.length >= 5){
                    //window.ga('send', 'event', [eventCategory], [eventAction], [eventLabel], [eventValue]);
                    let eventCat = args[2]
                    if(window.location.host.toLowerCase().indexOf('.tbbt.com.tw') >= 0){
                        eventCat+="_test";
                    }
                    let paras = {
                        'send_to': 'G-KQP8HNH5GP',
                        'eventAction': args[3],
                        'eventLabel': args[4],
                    }
                    if(args.length === 6){
                        paras['eventValue'] = args[5];
                    }
                    gtag(args[1], eventCat, paras);
                } else if(args.length === 3 && args[1] === 'conversion') {
                    gtag('event', 'conversion', args[2]);
                } else {
                    /*
                     *
                        window.ga('send', {
                            hitType: 'event',
                            eventCategory: 'shopping',
                            eventAction: 'order',
                            eventLabel: pageData.orderId,
                            eventValue: tempTotal
                        });
                     *
                     */
                    let paras = args[1];
                    let eventCat = paras.eventCategory;
                    if(window.location.host.toLowerCase().indexOf('.tbbt.com.tw') >= 0){
                        eventCat+="_test";
                    }
                    let hitType = paras.hitType;
                    delete paras['hitType'];
                    delete paras['eventCategory'];
                    paras["send_to"] = 'G-KQP8HNH5GP';
                    if(!!hitType && !!eventCat){
                        gtag(hitType, eventCat, paras);
                    }
                }
            }
            tmpStore.forEach((args)=>{
                window.ga(...args);
            })
        }
        let s = document.getElementsByTagName('script')[0];
        s.parentNode.insertBefore(script, s);
    }


    function setupFacebookPixel(id) {
        !function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = '2.0';
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
        }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

        let cookieToken = getEhToken();
        if (cookieToken != null) {
            let usr = cookieToken.user;
            let email = null;
            let eSys = cookieToken.ehSystem;
            if (!!eSys) {
                let sessionId = eSys.sessionId;
                if (!!usr) {
                    tempId = !!usr.userId ? usr.userId : "none";
                    email = !!usr.email ? usr.email : null;
                } else {
                    tempId = "none";
                }

                //pixel
                if (!!email) {
                    fbq('init', id, {'em': email, 'external_id': sessionId});
                } else {
                    fbq('init', id, {'external_id': sessionId});
                }
            }
        } else {
            fbq('init', id);
        }

        //call server api
        trackFBEvent('PageView', null);
    }

    function trackFBEvent(eventName, data) {
        if (!eventName) {
            return;
        }
        let now = new Date().getTime();
        let sendFbqEventId = {'eventID': `${tempId}_${now}`};
        if (!data) {
            data = {};
        } else {
            delete data.eventId;
        }
        fbq('track', eventName, data, sendFbqEventId);
        data["eventId"] = `${tempId}_${now}`;
        let url = `/user-bg/event/trackFBEvent?eventName=${eventName}`;
        let body = {};
        body.uri = window.location.href;
        body.data = data;
        fetch(url, {
            method: "POST",
            headers: {"Content-type": "application/json;charset=utf-8"},
            body: JSON.stringify(body)
        }).catch(_ => {});
    }

    function getEhToken() {
        const cookie = getCookieByName("eh-token");

        if (cookie) {
            try {
                return parseJwt(cookie);
            } catch (e) {
                console.error(e);
                return null;
            }
        }
        return null;
    }

    function getCookieByName(name) {
        return document.cookie.split(";").find(cookie => {
            return cookie.split('=')[0].trim() === name;
        });
    }

    const parseJwt = (token) => {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    var basicHttpEquiv = new Map([
        ['X-UA-Compatible', 'IE=edge'],
        ['cache-control', 'no-cache'],
        ['pragma', 'no-cache'],
        ['expires', '0'],
    ]);

    var basicProperty = new Map([
        ['og:title', 'eHanlin 翰林雲端學院'],
        ['og:description', '翰林雲端學院全臺國小、國中、高中線上學習第一品牌，超過1300間學校的學生家長老師唯一推薦。e評量、e名師、e家教貼合108新課綱，切合會考學測最新趨勢，幫助孩子有效規劃學習，順利培養自主學習力！服務電話02-82282575'],
        ['og:image', 'https://www.ehanlin.com.tw/images/indexImg.png'],
        ['og:url', 'https://www.ehanlin.com.tw/'],
        ['og:locale', 'zh_TW'],
        ['og:phone_number', '02-82282575'],
        ['og:site_name', '翰林雲端學院 - 線上學習首選 - 免費試用小中高課程'],
    ]);

    function setupMataData() {
        let head = document.getElementsByTagName('head')[0];
        let meta = document.createElement('meta');
        meta.charset = 'UTF-8';
        head.appendChild(meta);
        meta = document.createElement('meta');
        meta.name = 'viewport';
        meta.content = 'width=device-width, initial-scale=1, shrink-to-fit=no';
        head.appendChild(meta);
        basicHttpEquiv.forEach(k, v => {
            meta = document.createElement('meta');
            meta.httpEquiv = k;
            meta.content = v;
            head.appendChild(meta);
        });

        basicProperty.forEach(k, v => {
            meta = document.createElement('meta');
            meta.setAttribute("property", k);
            meta.content = v;
            head.appendChild(meta);
        });
        let title = document.createElement('title');
        title.text = "翰林雲端學院 - 線上學習首選 - 免費試用小中高課程";
        head.appendChild(title);

        meta = document.createElement('meta');
        meta.name = "description";
        meta.content = "翰林雲端學院全臺國小、國中、高中線上學習第一品牌，超過1300間學校的學生家長老師唯一推薦。e評量、e名師、e家教貼合108新課綱，切合會考學測最新趨勢，幫助孩子有效規劃學習，順利培養自主學習力！服務電話02-82282575";
        head.appendChild(meta);

        let link = document.createElement('link');
        link.rel = "stylesheet";
        link.href = "../platform-wc/platform-css/dist/css/layout.css";
        head.appendChild(link);

        link = document.createElement('link');
        link.rel = "icon";
        link.href = "/images/favicon.png";
        link.type = "image/png";
        head.appendChild(link);
    }

    if (window.location.host.toLowerCase().indexOf('.ehanlin.com.tw') >= 0) {
        //正式機
        window.eHanlin.platform_env.site = 'production';
        window.eHanlin.platform_env.cdn = "https://public.ehanlin.com.tw";

        setupGoogleAnalytics4();
        setupFacebookPixel("173626790647861");
        window.fb = trackFBEvent;
    } else if (window.location.host.toLowerCase().indexOf('.tbbt.com.tw') >= 0) {
        //測試機
        window.eHanlin.platform_env.site = 'test'
        setupGoogleAnalytics4();
        setupFacebookPixel("477550739618411");
        window.fb = trackFBEvent;
    } else if (window.location.protocol.toLowerCase() === 'file:') {
        //本機檔案系統
        window.eHanlin.platform_env.site = 'local-file';
    }

})();
